<script>
import draggable from "vuedraggable";
import Status from "../../../../../../helpers/general/status";
import AddIndividualMediaButton from "../../../../../../components/buttons/add-individual-media-button.vue";
export default {
    props: {
        value: Array,
        product_attributes: Array,
        product_sku: String,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            product_status: Status.getList('products'),
            stock_status: Status.getList('stock_status'),
            backorder_status: Status.getList('backorder_status'),
        }
    },
    components: {   
        draggable,
        AddIndividualMediaButton,
    },
    methods: {
        addVariation(){
            this.value.push({
                status: 'publish',
                description: null,
                sku: null,
                regular_price: null,
                sale_price: null,
                date_on_sale_from: null,
                date_on_sale_from_gmt: null,
                date_on_sale_to: null,
                date_on_sale_to_gmt: null,
                virtual: false,
                downloadable: false,
                download_limit: -1,
                download_expiry: -1,
                external_url: null,
                button_text: null,
                tax_status: 'taxable',
                tax_class: null,
                manage_stock: false,
                stock_quantity: null,
                stock_status: 'instock',
                backorders: 'no',
                weight: null,
                length: null,
                width: null,
                height: null,
                shipping_class: null,
                media: null,
                product_variation_attributes: {},
            });

            },
        deleteVariation(key){
            this.value.splice(key, 1);
        },
    },
};
</script>

<template>
    <div>
        <div role="tablist">
            <draggable group="product-variations" :list="value">
                <b-card no-body class="mb-1 shadow-none" v-for="(variation, i) in value" :key="i" :set="vvar = v.product.product_variations.$each[i]">
                    <b-card-header header-tag="header" role="tab">
                    <h6 class="m-0 d-flex justify-content-between align-items-center" v-b-toggle="'variation-accordion-' + i">
                        <div class="form-inline">
                            <select
                                class="form-control mr-2"
                                v-for="(attribute, i) in product_attributes"
                                :key="i"
                                @click.stop
                                v-model="variation.product_variation_attributes[attribute.name]"
                            >
                                <option value="">-- {{ attribute.name }} --</option>
                                <option v-for="option in attribute.options.split('|')" :key="option" :value="option">{{ option }}</option>
                            </select>
                        </div>

                        <div>
                            <a
                                href="javascript:void(0);"
                                class="text-secondary"
                                v-b-tooltip.hover
                                title="Ordenar"
                            >
                                <i class="mdi mdi-sort font-size-18"></i>
                            </a>
                            
                            <a
                                href="javascript:void(0);"
                                class="text-danger"
                                v-b-tooltip.hover
                                title="Eliminar"
                                @click="deleteVariation(i)"
                            >
                                <i class="mdi mdi-trash-can font-size-18"></i>
                            </a>                                                        
                        </div>                                                    
                    </h6>
                    </b-card-header>
                    <b-collapse :id="'variation-accordion-' + i" visible accordion="variation-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <label>Imagen</label>
                                    <AddIndividualMediaButton :onlyImages="true" v-model="variation.media"></AddIndividualMediaButton>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="form-group col-12">
                                        <label :for="'variation-sku-' + i">SKU</label>
                                        <input
                                            :id="'variation-sku-' + i"
                                            v-model="variation.sku"
                                            type="text"
                                            class="form-control"
                                            :placeholder="product_sku"
                                            :class="{ 'is-invalid': vvar.sku.$error && submitted }"
                                        />
                                    </div>
                                </div>

                                <div class="col-12 form-inline border-top border-bottom pb-2 pt-2 mb-2">
                                    <div class="form-group mr-2">
                                        <label :for="'variation-status-' + i" class="mr-2">Estado</label>
                                        <select v-model="variation.status" class="form-control" :id="'variation-status-' + i"
                                            :class="{ 'is-invalid': vvar.status.$error && submitted }">
                                            <option v-for="option in product_status" v-bind:value="option.id" :key="option.id">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>                      
                                    <div class="form-group custom-control custom-checkbox custom-control-right mr-2">
                                        <input
                                            type="checkbox"
                                            v-model="variation.virtual"
                                            class="custom-control-input"
                                            :id="'variation-virtual-' + i"
                                            :class="{ 'is-invalid': vvar.virtual.$error && submitted }"
                                        />
                                        <label class="custom-control-label" :for="'variation-virtual-' + i">Virtual</label>
                                    </div>
                                    <div class="form-group custom-control custom-checkbox custom-control-right mr-2">
                                        <input
                                            type="checkbox"
                                            v-model="variation.downloadable"
                                            class="custom-control-input"                                                                        
                                            :id="'variation-downloadable-' + i"
                                            :class="{ 'is-invalid': vvar.downloadable.$error && submitted }"
                                        />
                                        <label class="custom-control-label" :for="'variation-downloadable-' + i">Descargable</label>
                                    </div>
                                    <div class="form-group custom-control custom-checkbox custom-control-right mr-2">
                                        <input
                                            type="checkbox"
                                            v-model="variation.manage_stock"
                                            class="custom-control-input"
                                            :id="'variation-manage_stock-' + i"
                                            :class="{ 'is-invalid': vvar.manage_stock.$error && submitted }"
                                        />
                                        <label class="custom-control-label" :for="'variation-manage_stock-' + i">Gestionar inventario?</label>
                                    </div>
                                </div>

                                <div class="form-group col-6">
                                    <label :for="'variation-regular_price-' + i">Precio normal</label>
                                    <input
                                        :id="'variation-regular_price-' + i"
                                        v-model="variation.regular_price"
                                        type="text"
                                        class="form-control"
                                        placeholder="Ingresa el precio regular del producto"
                                        :class="{ 'is-invalid': vvar.regular_price.$error && submitted }"
                                    />
                                </div>
                                <div class="form-group col-6">
                                    <label :for="'variation-sale_price-' + i">Precio con carné joven</label>
                                    <input
                                        :id="'variation-sale_price-' + i"
                                        v-model="variation.sale_price"
                                        type="text"
                                        class="form-control"
                                        placeholder="Ingresa el precio con carné del producto"
                                        :class="{ 'is-invalid': vvar.sale_price.$error && submitted }"
                                    />
                                </div>

                                <div class="form-group col-12" v-if="!variation.manage_stock">
                                    <label :for="'variation-stock_status-' + i">Estado de inventario</label>
                                    <select
                                        v-model="variation.stock_status"
                                        :id="'variation-stock_status-' + i"
                                        class="form-control"
                                        :class="{ 'is-invalid': vvar.stock_status.$error && submitted }">
                                        <option v-for="option in stock_status" v-bind:value="option.id" :key="option.id">
                                            {{ option.label }}
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group col-6" v-if="variation.manage_stock">
                                    <label :for="'variation-stock_quantity-' + i">Cantidad</label>
                                    <input
                                        :id="'variation-stock_quantity-' + i"
                                        v-model="variation.stock_quantity"
                                        type="text"
                                        class="form-control"
                                        placeholder="Ingresa la cantidad de inventario del producto"
                                        :class="{ 'is-invalid': vvar.stock_quantity.$error && submitted }"
                                    />
                                </div>
                                <div class="form-group col-6" v-if="variation.manage_stock">
                                    <label :for="'variation-backorders-' + i">¿Permitir reservas?</label>
                                    <select v-model="variation.backorders"
                                        :id="'variation-backorders-' + i"
                                        class="form-control"
                                        :class="{ 'is-invalid': vvar.backorders.$error && submitted }">
                                        <option v-for="option in backorder_status" v-bind:value="option.id" :key="option.id">
                                            {{ option.label }}
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group col-12 col-lg-3" v-if="!variation.virtual">
                                    <label :for="'variation-weight-' + i">Peso (kg)</label>
                                    <input
                                        :id="'variation-weight-' + i"
                                        v-model="variation.weight"
                                        type="text"
                                        class="form-control"
                                        placeholder="Ingresa el peso del producto"
                                        :class="{ 'is-invalid': vvar.weight.$error && submitted }"
                                    />
                                </div>                                   
                                <div class="form-group col-12 col-lg-3" v-if="!variation.virtual">
                                    <label :for="'variation-length-' + i">Longitud (cm)</label>
                                    <input
                                        :id="'variation-length-' + i"
                                        v-model="variation.length"
                                        type="text"
                                        class="form-control"
                                        placeholder="Ingresa la longitud del producto"
                                        :class="{ 'is-invalid': vvar.length.$error && submitted }"
                                    />
                                </div>                                    
                                <div class="form-group col-12 col-lg-3" v-if="!variation.virtual">
                                    <label :for="'variation-width-' + i">Ancho (cm)</label>
                                    <input
                                        :id="'variation-width-' + i"
                                        v-model="variation.width"
                                        type="text"
                                        class="form-control"
                                        placeholder="Ingresa el ancho del producto"
                                        :class="{ 'is-invalid': vvar.width.$error && submitted }"
                                    />
                                </div>                                    
                                <div class="form-group col-12 col-lg-3" v-if="!variation.virtual">
                                    <label :for="'variation-height-' + i">Alto (cm)</label>
                                    <input
                                        :id="'variation-height-' + i"
                                        v-model="variation.height"
                                        type="text"
                                        class="form-control"
                                        placeholder="Ingresa el alto del producto"
                                        :class="{ 'is-invalid': vvar.height.$error && submitted }"
                                    />
                                </div>
                                <div class="form-group col-12" v-if="!variation.virtual">
                                    <label :for="'variation-shipping_class-' + i">Clase de envío</label>
                                    <input
                                        :id="'variation-shipping_class-' + i"
                                        v-model="variation.shipping_class"
                                        type="text"
                                        class="form-control"
                                        placeholder="Igual que el padre"
                                        :class="{ 'is-invalid': vvar.shipping_class.$error && submitted }"
                                    />
                                </div>
                                <div class="form-group col-12">
                                    <label :for="'variation-tax_class-' + i">Clase de impuesto</label>
                                    <input
                                        :id="'variation-tax_class-' + i"
                                        v-model="variation.tax_class"
                                        type="text"
                                        class="form-control"
                                        placeholder="Igual que el padre"
                                        :class="{ 'is-invalid': vvar.tax_class.$error && submitted }"
                                    />
                                </div>
                                <div class="form-group col-12">
                                    <label :for="'variation-description-' + i">Descripción</label>
                                    <textarea
                                        :id="'variation-description-' + i"
                                        v-model="variation.description"
                                        class="form-control"
                                        :maxlength="225"
                                        rows="3"
                                        placeholder="Introduce una descripción opcional para esta variación."
                                        :class="{ 'is-invalid': vvar.description.$error && submitted }"
                                    ></textarea>
                                </div>
                            </div>
                        </b-card-text>
                    </b-card-body>
                    </b-collapse>
                </b-card>
            </draggable>
        </div>
        <b-alert
            show
            variant="secondary"
            class="text-center"
            v-if="!value.length"
        >                                        
            <a
                href="javascript:void(0);"
                class="text-success"
                @click="addVariation()"
            >
                <i class="mdi mdi-plus-circle font-size-18"></i> Agregar variación
            </a>
        </b-alert>
        <div class="text-right" v-if="value.length">
            <a
                href="javascript:void(0);"
                class="text-success"
                @click="addVariation()"
            >
                <i class="mdi mdi-plus-circle font-size-18"></i> Agregar variación
            </a>
        </div>
    </div>
</template>