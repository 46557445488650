<script>
import QlkMedia from "../upload/qlk-media.vue";
import QlkMediaPreview from "../upload/qlk-media-preview.vue";
export default {
  props: {
    value: Object,
    uploadModalId: {
        type: String,
        default: 'add-individual-media-modal'
    },
    fullWidth:{
        type: Boolean,
        default: false
    },
    onlyImages: {
        type: Boolean,
        default: false
    },
    type: {
        type: [Array, String],
        default: null
    },
    extension: {
        type: [Array, String],
        default: null
    },
    mimeType: {
        type: [Array, String],
        default: null
    },
  },
  data() {
    return {      
    }
  },
  components: {
      QlkMedia,
      QlkMediaPreview
  },
  computed: {
    title(){
        return this.value ? 'Cambiar medio' : 'Añadir medio'
    }
  },
  methods: {
    showUploadModal() {
        this.$bvModal.show(this.uploadModalId);
    },
    updateValue(image) {
      // Emitir el nuevo valor al padre cuando cambia el input
      this.$emit('input', image);
    },
    deleteImage() {
        this.$emit('input', null);
    }
  }
}
</script>

<template>
  <div>
    <QlkMedia
      :modal-id="uploadModalId"
      :single="true"
      :onlyImages="onlyImages"
      :type="type"
      :extension="extension"
      :mimeType="mimeType"
      :selected="value"
      @onSuccess="updateValue"
    />
    <div class="qlk-preview-item" :class="{'full-width' : fullWidth}">
        <div class="qlk-preview-item-delete" v-if="value">
            <i class="ri-close-circle-fill text-danger" @click="deleteImage()" v-b-tooltip.hover title="Eliminar"></i>
        </div>
        <div class="qlk-preview-item-thumbnail" @click="showUploadModal()" v-b-tooltip.hover :title="title">
            <QlkMediaPreview :item="value" size="70px" v-if="value"></QlkMediaPreview>
            <i class="mdi mdi-plus-circle font-size-18" v-if="!value"></i>
        </div>
    </div>
  </div>
</template>